<template>
  <div>
    <v-sheet>
      <v-card>
        <v-card-title>Турнирная таблица</v-card-title>
      </v-card>
      <v-simple-table class="mе-4 mb-4 ml-0 mr-0">
        <template v-slfot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th class="text-left">Игрок</th>
              <th class="text-left">Подразделение</th>
              <th class="text-left">Филиал</th>
              <th class="text-center">Баллы</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in displayItems" :key="item.id">
              <td class="text-left" :style="{ ...calcFontStyles(item.index) }">
                {{ item.index }}
              </td>
              <td
                class="text-left"
                style="text-transform: capitalize"
                :style="{ ...calcFontStyles(item.index) }"
              >
                {{ item.name }}
              </td>
              <td class="text-left" :style="{ ...calcFontStyles(item.index) }">
                {{ item.dept }}
              </td>
              <td class="text-left" :style="{ ...calcFontStyles(item.index) }">
                {{ item.region }}
              </td>
              <td
                class="text-center"
                :style="{ ...calcFontStyles(item.index) }"
              >
                {{ item.points }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'Advent2022Standings',
  props: ['item-id', 'items', 'my-points', 'my-game-id'],
  computed: {
    ...mapGetters({
      meta: 'event/eventMeta',
    }),
    itemsWithName() {
      return _.filter(this.items, (item) => !!item.name);
    },
    sortedItems() {
      return _.orderBy(
        this.itemsWithName,
        ['points', 'name', 'user'],
        ['desc', 'asc', 'asc']
      );
    },
    displayItems() {
      return this.sortedItems.map((item, index) => {
        return {
          ...item,
          index: index + 1,
        };
      });
    },
  },
  methods: {
    calcFontStyles(index) {
      const topPlayers = 5;

      const delta = this.maxFontSize - this.minFontSize;
      const multiplier = Math.max(topPlayers - index - 1, 0) / topPlayers;
      const fontSize = this.minFontSize + multiplier * delta;
      return {
        fontSize: `${fontSize.toFixed(3)}rem`,
      };
    },
  },
  data() {
    return {
      minFontSize: 0.875, // rem
      maxFontSize: 1.5 * 0.875,
    };
  },
};
</script>

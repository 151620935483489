<template>
  <app-event event-id="otr2022" auth="login-only" :mapping="mapping">
    <template v-slot:logo>
      <div class="ma-4">
        <v-img
          src="https://cdn.your-quest.com/otr-2022/theme/logo/4.png"
          class="ma-4"
        />
      </div>
    </template>
  </app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import Quest from "./Quest.vue";
import KidsGallery from "./KidsGallery.vue";
import Shop from "./Shop/Index.vue";

export default {
  name: "AppPageEvent",
  components: {
    AppEvent,
  },
  data() {
    return {
      mapping: {
        quest: Quest,
        "kids-gallery": KidsGallery,
        shop: Shop,
      },
    };
  },
};
</script>

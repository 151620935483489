<template>
  <v-card :elevation="4" class="my-4">
    <v-img :src="url" @dblclick="onDoubleClick" />
    <v-card-title class="gallery-title" v-if="author"
      >Это желание берется исполнить {{ author }}</v-card-title
    >
    <v-card-actions>
      <v-tooltip bottom color="primary" v-if="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="red"
            @click="onDoubleClick"
            text
            fab
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{
              isLikedByUser ? "mdi-heart" : "mdi-heart-outline"
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{ isLikedByUser ? "Убрать лайк" : "Поставить лайк!" }}</span>
      </v-tooltip>
      <div v-if="usersStr">
        <v-icon color="red">mdi-heart</v-icon>
        {{ usersStr }}
      </div>
      <v-btn v-else @click="confirmDialog = true" depressed text color="red">
        <v-icon class="mr-2">mdi-heart</v-icon> ИСПОЛНИТЬ ЖЕЛАНИЕ
      </v-btn>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            @click="onZoomClick"
            text
            fab
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>Увеличить</span>
      </v-tooltip>
    </v-card-actions>
    <v-dialog
      v-model="confirmDialog"
      :max-width="multiline ? 720 : 400"
      persistent
    >
      <v-card>
        <v-card-title class="text-subtitle-1"> Вы уверены? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="secondary" text @click="confirmDialog = false">
            Нет
          </v-btn>

          <v-btn color="primary" text @click="onDoubleClick"> Да </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import likeableMixin from "../../mixins/likeable";
import { mapGetters } from "vuex";

export default {
  name: "KidGalleryItem",
  props: ["id", "image", "author", "userLikesWithAuthors", "index"],
  mixins: [likeableMixin],
  data() {
    return { confirmDialog: false, exclusive: true };
  },
  computed: {
    ...mapGetters({
      event: "event/event",
      likes: "event/likes",
    }),
    likeableObjectId() {
      return this.id;
    },
    url() {
      return this.image;
    },
    total() {
      return (this.likes && this.likes[this.id]) || 0;
    },
    users() {
      return this.userLikesWithAuthors[this.id] || [];
    },
    usersStr() {
      const users = this.users.map((user) => user.name);
      return users.length
        ? `Это желание взялся исполнить: ${users.join(", ")}`
        : "";
    },
  },
  watch: {
    isLikedByUser() {
      setTimeout(() => this.$emit("update"), 500);
    },
  },
  methods: {
    onDoubleClick() {
      this.confirmDialog = false;
      this.isLikedByUser = !this.isLikedByUser;
    },
    onZoomClick() {
      window.open(this.url, "_blank");
    },
  },
};
</script>
<template>
  <div>
    <ul>
      <gallery-item
        v-for="kid in kids"
        :key="kid.id"
        v-bind="kid"
        :user-likes-with-authors="likes"
        @update="onUpdate"
      />
    </ul>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import GalleryItem from "./KidsGalleryItem.vue";
import api from "../../api";

export default {
  name: "KidsGallery",
  components: {
    GalleryItem,
  },
  created() {
    this.fetchUserLikes();
  },
  computed: {
    ...mapGetters({
      eventId: "event/eventId",
    }),
  },
  methods: {
    async fetchUserLikes() {
      try {
        const ids = this.ids;
        const { data: likes } = await api.getEventLikesWithAuthors(
          this.eventId,
          ids
        );
        this.likes = likes;
      } catch (err) {
        console.error(err);
      }
    },
    onUpdate() {
      this.fetchUserLikes();
    },
  },
  data() {
    /**
     * Вместо 1, 2 - 1_, 2_
     * Вместо 9 (Мария, Артем, Серафим Иванкины) - 9_
     * Вместо 18 (Мария Мельникова) - 18_ (Мария Смолина)
     * Новые:
     * 33 (Екатерина Понятова) -- удалена
     * 34 (Надежа Седова)
     */
    return {
      kids: [
        ..._.times(1, (n) => {
          const index = n + 34;
          return {
            id: `kid-${index}`,
            index,
            image: `https://cdn.your-quest.com/otr-2022/kids/${index}${
              [1, 2, 9, 18].includes(index) ? "_" : ""
            }.JPG`,
          };
        }),
        ..._.times(32, (n) => {
          const index = n + 1;
          return {
            id: `kid-${index}`,
            index,
            image: `https://cdn.your-quest.com/otr-2022/kids/${index}${
              [1, 2, 9, 18].includes(index) ? "_" : ""
            }.JPG`,
          };
        }),
      ],
      ids: _.times(34, (n) => {
        return `kid-${n + 1}`;
      }),
      likes: {},
      timer: null,
    };
  },
};
</script>